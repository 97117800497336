import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
//import store from "./store";
import VueMq from "vue3-mq";
import Vant from "vant";

import "vant/lib/index.css";
const app = createApp(App).use(router).use(Vant).use(Vant.Lazyload);
//app.use(store)
app.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    sm: 450,
    md: 1250,
    lg: Infinity,
  },
  defaultBreakpoint: "sm", // customize this for SSR
});
app.mount("#app");

// The Lazyload directive needs to be registered separately
