<template>
  <div class="home">
    <div class="">
      <van-space direction="vertical" fill class="input">
        <van-field
          label="アドレス"
          v-model="post_data.email"
          type="email"
          placeholder="例)abc@xyz.ne.jp"
        />
      </van-space>
    </div>
    <van-button
      :disabled="!post_data.email || btn_disable"
      @click="post"
      block
      type="primary"
      >送信</van-button
    >
  </div>
</template>
<style lang="scss">
.home {
  display: flex;
  width: 100%;
  flex-direction: column;
  > div {
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    .input {
      width: 100%;
    }
    width: 100%;
    display: flex;
  }
}
</style>
<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import axios from "axios";
import { showSuccessToast, showFailToast } from "vant";
export default {
  name: "HomeView",
  components: {
    //HelloWorld,
  },
  data() {
    return {
      post_data: {
        email: null,
        id: null,
      },
      btn_disable: false,
    };
  },
  methods: {
    post() {
      //alert("test");
      this.btn_disable = true;
      axios
        .post("https://api.classlab.co.jp/api/mailform", this.post_data)
        .then(function (response) {
          //vm.init(true);
          console.log(response);
          if (response.data == "ok") {
            showSuccessToast("送信しました。");
          } else {
            showFailToast("すでに送信済みです。");
          }
          this.btn_disable = false;
        })
        .catch(function (e) {
          console.log(e);
          this.btn_disable = false;
          showFailToast("すでに送信済みです。");
        });
    },
  },
  created() {
    if (!(window.location.search && this.$route.query.id)) {
      alert("URLが不正です");
      window.location = "https://classlab.co.jp/ ";
    } else {
      this.post_data.id = this.$route.query.id;
    }
  },
};
</script>
<style>
.home {
  height: 100%;
  width: 100%;
}
</style>
